/** 政策服务 */
<template>
  <div :class="['policy', 'content', { 'mt-30': $isPc }]">
    <section class="select-con justify-between align-center" :class="{ 'flex-wrap': !$isPc }">
      <div :class="['select-list', 'flex', { 'flex-wrap': !$isPc }]">
        <div :class="[{ 'mb-10': !$isPc }]">
          <label class="select-label">类别</label>
          <el-radio-group v-model="filtersData.article_type" @change="handlefilter()">
            <el-radio label="金融政策">金融政策</el-radio>
            <el-radio label="融资补贴">融资补贴</el-radio>
          </el-radio-group>
        </div>

        <el-divider v-if="$isPc" direction="vertical"></el-divider>
        <div>
          <label class="select-label">层级</label>
          <el-radio-group v-model="filtersData.article_level" @change="handlefilter()">
            <el-radio label="国家">国家</el-radio>
            <el-radio label="天津市">天津市</el-radio>
            <el-popover placement="bottom" :width="$isPc ? '400' : '300'" v-model="regionVisible">
              <el-radio label="区" @click.native.prevent="changeRegion('区')" slot="reference">区</el-radio>
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-row>
                  <el-col :xs="8" :sm="6" v-for="city in cities" :key="city">
                    <el-checkbox :label="city">{{ city }}</el-checkbox>
                  </el-col>
                </el-row>
              </el-checkbox-group>
              <div class="select-area-btn align-center">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全部
                </el-checkbox>
                <el-button type="primary" size='mini' class="ml-20" @click="handlefilter()">确定</el-button>
              </div>
            </el-popover>
          </el-radio-group>
        </div>
      </div>
      <Search backColor="#fff" @searchBtn="searchBtn" :class="{ 'pa-0': !$isPc }" />
    </section>
    <LoadMore :onLoadMore="onLoadMore" :loading="loading" :finished="finished">
      <List :data="policyData" :loading="loading" :total="pageTotal" :resetPage="resetPage" @page="changePage">
        <template v-slot="{ item }">
          <router-link :to="{ path: '/policy_details', query: { id: item.id } }">
            <div :class="['item-info', { 'flex-1': $isPc }, 'flex-column', $isPc ? 'justify-between' : 'justify-around']">
              <div class="justify-between">
                <h4 class="item-title text-ellipsis-lines">
                  {{ item.title }}
                </h4>
                <el-tag v-if="item.is_top == 2" type="danger" effect="dark">
                  置顶
                </el-tag>
              </div>
              <div v-if="$isPc" class="item-info-text text-ellipsis-lines">
                {{ $settleHtml(item.details) }}
              </div>
              <div class="item-info-desc justify-between align-center">
                <div class="align-center">
                  <span class="mr-20 text-ellipsis">
                    发布来源： {{ item.unit }}
                  </span>
                  <template v-if="$isPc">
                    <el-tag class="mr-20" v-for="(item, index) in item.tag" :key="index" color="#EFF3FA"
                      v-show="item != ''" type="info" size="small">
                      {{ item }}
                    </el-tag>
                  </template>
                </div>
                <span style="float:right">
                  发布时间：{{ item.release_at | filterTime }}
                </span>
              </div>
            </div>
          </router-link>
        </template>
      </List>
    </LoadMore>
  </div>
</template>
<script>
import { getArticle } from '@/api/index'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
import Search from '@/components/Search.vue'
export default {
  name: 'Policy',
  components: {
    LoadMore,
    List,
    Search
  },
  data () {
    return {
      filtersData: {
        article_type: '金融政策',
        article_level: '国家',
        keywords: '',
        page: 1
      },
      regionVisible: false,//区域弹框
      checkAll: true,// 是否全选
      checkedCities: [],//已选区域
      // 天津市所有区域
      cities: ['和平区', '河东区', '河西区', '红桥区', '东丽区', '西青区', '津南区', '武清区', '宝坻区', '滨海新区', '宁河区', '蓟州区', '南开区', '河北区', '北辰区', '静海区'],
      isIndeterminate: false,//全选框样式
      pageTotal: 0,
      policyData: [],
      loading: false,//正在加载
      finished: false, // 加载完成
      isReset: false,//是否重置数据
      resetPage: false,//分页重置
    }
  },
  mounted () {
    this.checkedCities = this.cities
    this.onLoadMore()
  },
  methods: {
    async getPolicyData (filtersData) {
      try {
        let { data, total } = await getArticle(filtersData)
        this.policyData = this.$isPc ? data : [...this.policyData, ...data]
        this.pageTotal = total
        this.isReset = false
        this.loading = false;
      } catch (error) { }
    },
    // 筛选项切换
    handlefilter () {
      if (this.filtersData.article_level == '区') {
        if (!this.checkedCities.length) return this.$message.warning('请选择区域');
      }
      this.isReset = true
      this.onLoadMore()
    },
    // 切换层级
    changeRegion (val) {
      if (val == '区') {
        this.filtersData.article_level = val
        this.checkedCities = this.cities
        this.checkAll = true
        setTimeout(() => {
          this.regionVisible = true
        }, 0);
      }
    },
    // 区域全选
    handleCheckAllChange (val) {
      this.checkedCities = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    // 区域单选
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 搜索
    searchBtn (val) {
      this.filtersData.keywords = val
      this.isReset = true
      this.onLoadMore()
    },
    // 整合查询数据
    async setFiltersData () {
      if (this.isReset) {
        this.pageTotal = 0
        this.policyData = []
        this.filtersData.page = 1
        this.resetPage = !this.resetPage
        this.loading = true
        this.finished = false
      }
      if (!this.filtersData.keywords) {
        delete this.filtersData.keywords
      }
      let newFiltersData = JSON.parse(JSON.stringify(this.filtersData));
      if (this.filtersData.article_level == '区' && this.checkedCities.length) {
        newFiltersData.article_level = this.checkedCities
      }
      this.regionVisible = false
      await this.getPolicyData(newFiltersData)
    },
    // 切换分页
    changePage (page) {
      this.filtersData.page = page
      this.onLoadMore()
    },
    // 上拉加载
    async onLoadMore (done) {
      this.loading = true
      await this.setFiltersData()
      done && done();
      if (this.policyData.length >= this.pageTotal) {
        this.finished = true
        return
      }
      this.filtersData.page += 1
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/.select-con {
  padding: 20px;
  background-color: #eff3fa;

  .select-list {
    .select-label {
      font-size: 14px;
      color: #b3bcd0;
      margin-top: 2px;
    }

    .el-radio-group {
      .el-radio {
        margin-right: 0;

        .el-radio__input {
          visibility: hidden;
        }

        .el-radio__label {
          font-size: 16px;
        }
      }
    }

    .el-divider {
      margin: 0 60px;
      background-color: #c4c9d7;
    }
  }
}

.el-popover {
  .el-checkbox-group {
    .el-checkbox {
      width: 25%;
      margin-bottom: 10px;
    }
  }

  .el-checkbox {
    margin-right: 0;

    /deep/.el-checkbox__label {
      font-size: 16px;
    }

    /deep/.el-checkbox__inner {
      width: 16px;
      height: 16px;
    }
  }
}

.el-tag--danger {
  height: 26px;
  line-height: 24px;
  border-radius: 2px;
}

@media (max-width: 767px) {
  /deep/ .search {
    padding: 10px 0 0;
    width: 100%;
  }

  .el-tag--danger {
    height: 20px;
    line-height: 18px;
  }
}
</style>
<style>
.el-popover {
  padding: 12px;
}
</style>